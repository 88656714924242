jQuery(function($){

    $('[data-link-confirm]').click(function(e){
        e.preventDefault();
        
        if( confirm( $(this).attr('data-link-confirm') ) ){
            unsaved = false;
            document.location.href = $(this).attr('href');
        }
    });

    var unsaved = false;

    setTimeout(function(){
        $("form:not(#bookgenerator) :input").change(function(){
            unsaved = true;
        });
    }, 1500);

    $("form").submit(function() {
        unsaved = false;
    });

    $(window).bind('beforeunload', function(){
        if(unsaved) return 'Are you sure you want to leave?';
    });
})

var datatable_tin = {
    dom: "<'row'<'col-sm-6 col-xs-7'f><'col-sm-6 col-xs-5'l>r>t<'row'<'col-sm-5 hidden-xs'i><'col-sm-7 col-xs-12 clearfix'p>>",
    pageLength: 10,
    stateSave: true,
    lengthMenu: [
        [10, 20, 30, -1],
        [10, 20, 30, "All"]
    ],
    deferRender: true,
    bAutoWidth: false
}

function format_price(number, prefix, decimals)
{
    decimals = decimals || 2;
    return (prefix ? '€ ' : '') + number_format(number, decimals, ',', '.');
}

function number_format(number, decimals, dec_point, thousands_sep) {

    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');

    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function(n, prec) {
            var k = Math.pow(10, prec);
            return '' + (Math.round(n * k) / k)
                    .toFixed(prec);
        };

    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');

    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }

    if ((s[1] || '')
            .length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1)
            .join('0');
    }

    return s.join(dec);
}

function amount_dutch(data, thousands, decimal, precision, prefix, postfix) {
    thousands = (typeof thousands !== 'undefined') ? thousands : '.';
    decimal = (typeof decimal !== 'undefined') ? decimal : ',';
    precision = (typeof precision !== 'undefined') ? precision : 2;
    prefix = (typeof prefix !== 'undefined') ? prefix : '€ ';
    postfix = (typeof postfix !== 'undefined') ? postfix : '';

    var negative = data < 0 ? '-' : '';
    data = Math.abs(parseFloat(data));

    var intPart = parseInt(data, 10);
    var floatPart = precision ? decimal + (data - intPart).toFixed(precision).substring(2) : '';

    return (prefix || '') + negative + intPart.toString().replace(/\B(?=(\d{3})+(?!\d))/g, thousands) + floatPart + (postfix || '');

}